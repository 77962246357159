import React from "react"
import Header from '../layouts/Header'




const PrintPlay = () => {

    return (

        <div id='print-play'>
            <Header />


            <div className='pnp'>
                <p>Print & Play coming soon</p>
            </div>

        </div>



    )

}

export default PrintPlay
